// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {
  font-family: 'Netflix';
  src: url('/fonts/NetflixSans-Medium.woff2') format('woff2');
  src: url('/fonts/NetflixSans-Light.woff2') format('woff2');
  src: url('/NetflixSans-Bold.woff2') format('woff2');
  src: url('/NetflixSans-Regular.woff2') format('woff2');

  font-weight: normal;
 
} */

body {
  font-family: 'Netflix', sans-serif !important;
  margin: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;;;;;;GASG;;AAEH;EACE,6CAA6C;EAC7C,oBAAoB;EACpB,mCAAmC;EACnC,kCAAkC;AACpC;;;AAGA;EACE;aACW;AACb","sourcesContent":["/* @font-face {\r\n  font-family: 'Netflix';\r\n  src: url('/fonts/NetflixSans-Medium.woff2') format('woff2');\r\n  src: url('/fonts/NetflixSans-Light.woff2') format('woff2');\r\n  src: url('/NetflixSans-Bold.woff2') format('woff2');\r\n  src: url('/NetflixSans-Regular.woff2') format('woff2');\r\n\r\n  font-weight: normal;\r\n \r\n} */\r\n\r\nbody {\r\n  font-family: 'Netflix', sans-serif !important;\r\n  margin: 0 !important;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
